import React, { useState } from 'react';
import {Button, Modal} from 'react-bootstrap';
import moment from 'moment'
import {Link} from 'react-router-dom'
import 'react-notifications-component/dist/theme.css'
import property from '../../property/bills/property';

const Arreas = (props) => {

    const [bills, setBills] = useState(0)
    const [details, setDetails] = useState(0)
    const [upcomingBills, setUpcomingBills] = useState(0);
    
    return (
        <>
        {
            upcomingBills ? 

            <div className="folio_table transparent_modal_box folio_table_box">
                <Modal.Header>
                    <Modal.Title>{getPropertyName()}</Modal.Title>
                    <Button className="cross-button" variant="" onClick={() => setUpcomingBills(0)}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                
                <Modal.Body>
                    <table  className="table table-striped table-hover billDueTable layoutfixed folio_arrears_bill_table">
                        <thead>
                            <tr>
                                <th className='text-left'>Added On</th>
                                <th>Utility Type</th>
                                <th className='text-right'>Amount</th>
                                <th className='text-right'>Amount Paid</th>
                                <th className='text-right'>Balance</th>
                                <th className='text-right'>Document</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            renderPropertyUpcomingBills(props)
                        }
                        </tbody>
                    </table>
                </Modal.Body>
            </div>
            : 

            bills ?
        
            <div className="folio_table transparent_modal_box folio_table_box">
                <Modal.Header>
                    <Modal.Title>{getPropertyName()}</Modal.Title>
                    <Button className="cross-button" variant="" onClick={() => setBills(0)}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                
                <Modal.Body>
                    <table  className="table table-striped table-hover billDueTable layoutfixed folio_arrears_bill_table">
                        <thead>
                            <tr>
                                <th className='text-left'>Added On</th>
                                <th>Utility Type</th>
                                <th className='text-right'>Amount</th>
                                <th className='text-right'>Amount Paid</th>
                                <th className='text-right'>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            renderPropertyBills(props)
                        }
                        </tbody>
                    </table>
                </Modal.Body>
            </div>
            :
            <div className="folio_table transparent_modal_box folio_table_box">
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                    <Button className="cross-button" variant="" onClick={() => props.handlePopupEvent()}>
                        <i className="fa fa-times-circle fa-2x"></i>
                    </Button>
                </Modal.Header>
                
                <Modal.Body>
                    <table  className="table table-striped table-hover billDueTable layoutfixed folio_arrears_bill_table">
                        <tbody>
                            <tr>
                                <td className='text-left'><strong>Address</strong></td>
                                <td className='column_currency_format'><strong>Rent OverDue</strong></td>
                                <td className='text-right'><strong>Bills OverDue</strong></td>
                                <td className='text-right'><strong>Current Bills</strong></td>
                            </tr>
                        {
                            renderProperty(props)
                        }
                        </tbody>
                    </table>
                </Modal.Body>
            </div>
        }
       </>
    )

    function getTenantBillDetails()
    {
        const { folioData }   =  props

        try {

            const list  = (folioData?.propertyList?.filter(list => list?.property_id == bills))?.values()?.next()?.value
            const history = (list?.tenant_all_bills?.filter( bill_details => bill_details?.history?.transaction_id == details ))?.values()?.next()?.value
            const transaction_details = (history?.history?.transactions?.filter(list => list?.transaction_id == details))?.values()?.next()?.value

            return {
                list: list,
                history: history,
                transaction: transaction_details
            }
        } catch (error) {
            
        }
        return false
    }

    function renderPropertyBills(props) {

        const { folioData }   =  props

        const list  = (folioData?.propertyList?.filter(list => list?.property_id == bills))?.values()?.next()?.value

        const currentPropertyTenantIds = folioData.leasedProperty
            .flatMap(property => property.current_tenants || []) // Flatten the `current_tenants` arrays
            .map(tenant => tenant.tenant_id); // Extract the `tenant_id` values

        if( !list?.tenant_all_bills?.length ) return <h4>No Bill found!</h4>

        return list?.tenant_all_bills?.filter(listData => moment(listData?.due_date).isSameOrBefore(moment(), 'day'))?.map(tenantBill => {

            if( tenantBill?.total_bill_amount > 0 && tenantBill?.is_sd_invoice == 0 && currentPropertyTenantIds.includes(tenantBill.tenant_id)){

                let results  = getBillPaidAmount(tenantBill);
                let totalAmountToBePaid  = tenantBill?.total_bill_percent >  0 ? (((tenantBill?.total_bill_amount*tenantBill?.total_bill_percent)/100)?.toFixed(2)) : tenantBill?.total_bill_amount
                let balance = totalAmountToBePaid - results?.paid_amount
                if( balance > 0)
                {
                    return (
                        <>
                            <tr className={currentPropertyTenantIds.includes(tenantBill.tenant_id) ? 'tenant-current' : 'tenant-previous'}>
                                <td className="">{moment(tenantBill?.added_on)?.format('MM.DD.YYYY')}</td>
                                <td className="">{tenantBill?.utility?.utilitie_name}</td>
                                <td className="column_currency_format">${props.helper.currencySign(totalAmountToBePaid)}</td>
                                <td className="column_currency_format">${props.helper.currencySign(results?.paid_amount)}</td>
                                <td className="column_currency_format">{balance >= 0 ? '$'+props.helper.currencySign(balance) : '-$'+props.helper.currencySign(balance*-1)}</td>
                            </tr>
                        </>
                    )
                }
            }
        }, this)
    }

    function renderPropertyUpcomingBills(props) {

        const { folioData }   =  props
        
        const list  = (folioData?.propertyList?.filter(list => list?.property_id == upcomingBills))?.values()?.next()?.value

        if( !list?.tenant_all_bills?.length ) return <h4>No Bill found!</h4>

        return list?.tenant_all_bills?.filter(listData => moment(listData?.due_date).isAfter(moment(), 'day'))?.map(tenantBill => {
            
            if( tenantBill?.total_bill_amount > 0 && tenantBill?.is_sd_invoice == 0){

                let results  = getBillPaidAmount(tenantBill);
                let totalAmountToBePaid  = tenantBill?.total_bill_percent >  0 ? (((tenantBill?.total_bill_amount*tenantBill?.total_bill_percent)/100)?.toFixed(2)) : tenantBill?.total_bill_amount
                let balance = totalAmountToBePaid - results?.paid_amount
                if( balance > 0)
                {
                    return (
                        <>
                            <tr>
                                <td className="">{moment(tenantBill?.added_on)?.format('MM.DD.YYYY')}</td>
                                <td className="">{tenantBill?.utility?.utilitie_name}</td>
                                <td className="column_currency_format">${props.helper.currencySign(totalAmountToBePaid)}</td>
                                <td className="column_currency_format">${props.helper.currencySign(results?.paid_amount)}</td>
                                <td className="column_currency_format">{balance >= 0 ? '$'+props.helper.currencySign(balance) : '-$'+props.helper.currencySign(balance*-1)}</td>
                                <td className="text-right"><a target="_blank" href={folioData?.mediaUrl+'/'+tenantBill?.media?.path+tenantBill?.media?.name}>View</a></td>
                            </tr>
                        </>
                    )
                }
            }
        }, this)
    }

    function allTenantBills(transactions)
    {
        try {
            return transactions?.map(bill_id => {
                return (
                    <p className="bill_transaction" onClick={() => setDetails(bill_id)}>View</p>
                )
            })
        } catch (error) {
            
        }
    }

    function getBillPaidAmount(tenantBill)
    {
        let paid_amount = 0
        let transaction_ids = []
        try {
            tenantBill?.history?.forEach(history_item => {
                history_item?.transactions?.forEach(transaction => {
                    paid_amount += parseFloat(transaction?.amount);
                    transaction_ids?.push(transaction?.transaction_id);             
                });
            });
        } catch (error) {
            
        }
        return {paid_amount: paid_amount, transactions: transaction_ids}
    }

    function getUnpaidBillAmount(property)
    {
        let arrears = 0
        try {
            property?.tenant_all_bills?.filter(list => moment(list?.due_date) >= moment() && list?.status == 0)?.map( tenantBill => {
                if( tenantBill?.total_bill_amount > 0 && tenantBill?.is_sd_invoice == 0)
                {

                    let results  = getBillPaidAmount(tenantBill);
                    let totalAmountToBePaid  = tenantBill?.total_bill_percent >  0 ? (((tenantBill?.total_bill_amount*tenantBill?.total_bill_percent)/100)?.toFixed(2)) : tenantBill?.total_bill_amount
                    let balance = totalAmountToBePaid - results?.paid_amount
                    arrears += parseFloat(balance ?? 0)
                }
            })
        } catch (error) {}
        return arrears
    }

    function renderProperty(props) {

        const { folioData }   =  props

        const list  = folioData?.propertyList?.filter( property => props.helper.findBuildingStatus( property ) )

        if( !list?.length ) return <h4>No Bill found!</h4>

        return list?.map(property => {

            const currentPropertyTenantIds = folioData.leasedProperty
                .flatMap(property => property.current_tenants || []) // Flatten the `current_tenants` arrays
                .map(tenant => tenant.tenant_id); // Extract the `tenant_id` values

            let rentArrears = totalRentArrears(property);
            let billArrears = totalBillArrears(property, currentPropertyTenantIds);

            let unpaidBills = getUnpaidBillAmount(property);
            if (props.helper.findBuildingStatus(property) && (rentArrears > 0 || billArrears > 0)) {

                return (
                    <>
                        <tr>
                            <td className=""><Link to={"/tenancy/" + property?.property_id}>{property?.address}</Link>
                            </td>
                            <td className="column_currency_format">{props.helper.currencySign(rentArrears)}</td>
                            <td className="column_currency_format">
                                <div className='bill_details bill_transaction'
                                     onClick={() => setBills(property?.property_id)}>{props.helper.currencySign(billArrears)}</div>
                            </td>
                            <td className="column_currency_format"><a href="#" onClick={(e) => {
                                e.preventDefault();
                                setUpcomingBills(property?.property_id)
                            }}>{props.helper.currencySign(unpaidBills)}</a></td>
                        </tr>

                    </>
                )
            }
        }, this)
    }

    function getPropertyName()
    {
        const { folioData }   =  props
        return ((folioData?.propertyList?.filter(list => list?.property_id == bills))?.values()?.next()?.value)?.address
    }

    function totalRentArrears(property)
    {
        let arrears = 0
        try {
            arrears = parseFloat(property?.lease?.ar?.total_debt_rent ?? 0)
        } catch (error) {}
        return arrears
    }

    function totalBillArrears(property, tenants)
    {
        try {
            let arrears = 0;

            // Filter tenant bills that are due and valid
            const validBills = property?.tenant_all_bills?.filter(tenantBill => {
                // Check if the bill is due on or before today
                const isDue = moment(tenantBill?.due_date).isSameOrBefore(moment(), 'day');

                // Check if the bill has a valid owner account or is marked to show in the portal
                const isValidBill = tenantBill?.owner_account_id != null || tenantBill?.show_in_owner_portal;

                // Include bills that are both due and valid
                return isDue && isValidBill && tenants.includes(tenantBill.tenant_id);
            });

            // Calculate arrears from valid bills
            validBills?.forEach(tenantBill => {
                if (tenantBill?.total_bill_amount > 0 && tenantBill?.is_sd_invoice == 0) {
                    const results = getBillPaidAmount(tenantBill); // Get paid amount
                    const totalAmountToBePaid = tenantBill?.total_bill_percent > 0
                        ? ((tenantBill?.total_bill_amount * tenantBill?.total_bill_percent) / 100).toFixed(2)
                        : tenantBill?.total_bill_amount;

                    const balance = totalAmountToBePaid - (results?.paid_amount || 0); // Calculate balance
                    arrears += parseFloat(balance ?? 0); // Accumulate arrears
                }
            });

            return arrears; // Return total arrears
        } catch (error) {
            console.error("Error calculating arrears:", error); // Log errors for debugging
            return 0; // Return 0 as a fallback in case of an error
        }
    }
}

/**
 * net past due fr last month
 * 
 * @param {object} option 
 * @param {object} constants 
 * @returns 
*/
const arrears = (property) => 
{   
    let totalArrease  = 0
    try {

        if( property?.ars )
        {
            property?.ars?.map( (ar, index) => {

                if( ar?.total_debt_rent )
                    totalArrease    += parseFloat(ar?.total_debt_rent)

                if( ar?.total_debt_bill )
                    totalArrease    += parseFloat(ar?.total_debt_bill)
            })
        }
            return totalArrease
    } catch (error) {
        return totalArrease
    }
    return '0.00';
}

const tenantAllBills = ( tenantBills ) => {

    let totalBills = 0

    try {
        tenantBills?.map( bill => {
            totalBills  += parseFloat(bill?.total_bill_amount)
        })
    } catch (error) {
        
    }
    return totalBills
}

export default Arreas
